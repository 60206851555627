<template>
  <div class="discussions-wrapper">
    <top-nav />
    <div class="discussions-container">
      <div class="items-content">
        <div
          class="left-container large-7 item-inner"
        >
          <item-skeleton />
        </div>
        <div class="right-container large-5">
          <reply-section :thread.sync="thread" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSkeleton from '../../assets/items/ItemSkeleton'
import ReplySection from './ReplySection'
import TopNav from '../shared/TopNav'

import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    ItemSkeleton,
    ReplySection,
    TopNav
  },
  computed: {
    ...mapGetters([
      'thread'
    ])
  },
  created () {
    this.getThread(this.$route.params.id).then(() => {
      this.apiItemsById({ itemId: this.thread[0].asset_id }).then(response => {
        var record = response.data.records[0]
        this.setAsset({ asset: record })
        this.setAssetType({ assetType: 'item' })
        this.setDiscussionMode({ discussionMode: true })
      })
    })
  },
  methods: {
    ...mapActions([
      'apiItemsById',
      'getThread'
    ]),
    ...mapMutations([
      'setAsset',
      'setDiscussionMode',
      'setAssetType'
    ])
  }
}
</script>

<style lang="scss" scoped>
.discussions-wrapper {
  position: relative;
  height: 100%;
}
.discussions-container {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .items-content {
    display: flex;
    background-color: $blue-5;
    padding: 0px;
    .left-container {
      height: 92vh;
      overflow-y: auto;
      .item-skeleton {
        padding: 0 15px 100px 15px;
      }
    }
  }
  .left-container {
    background-color: white;
    padding: 0 20px 20px;
    margin-bottom: 50px;
    height: 100%;
    min-height: 850px;
    .item-id {
      display: inline-block;
    }
  }
  .right-container {
    background-color: $space-gray-20;
    top: 86px;
    padding: 20px;
    color: $blue-2;
  }
}
</style>
