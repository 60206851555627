<template>
  <div class='reply-section-container'>
    <div class='reply-topbar'>
      <h5 class='hlt=header h5'>Reply to Messages</h5>
    </div>
    <div class='inner-section'>
      <div class='reply-container'>
        <div
          v-for='(parentMsg, i) in parentThread'
          :key="'parent-message-' + parentMsg.id"
          class='thread-container'
        >
          <div v-for='(msg, index) in parentChildThread(parentMsg)' :key='"message-" + index + "-" + msg.id'>
            <div :class="[(msg.is_hlt_response ? 'admin-message' : 'user-message'), 'message' ]">
              <div class='message-row'>
                <div class='message-left'>
                  <small class="app-name" v-if="msg == parentMsg">{{ msg.app_name }}</small>
                  <small v-if="isShadowDeleted(msg)" class="shadow-deleted"> shadow deleted</small>
                  <label>{{ msg.first_name || msg.email }} says:</label>
                  <p class='message-date'>{{ prettyDate(msg.created_at) }}</p>
                </div>
                <div class='message-right'>
                  <new-message-svg v-if="!msg.is_hlt_response && msg.status == 'New'" class="message-status-icon" />
                  <monitor-svg v-if="!msg.is_hlt_response && msg.status == 'Monitor'" class="message-status-icon" />
                  <review-svg v-if="!msg.is_hlt_response && msg.status == 'Needs Review'" class="message-status-icon" />
                  <completed-svg v-if="!msg.is_hlt_response && msg.status == 'Completed'" class="message-status-icon" />

                  <div
                    class='thread-action'
                    @click='showActionMenu(msg)'
                    v-on-clickaway='() => activeMessage = null'
                  ></div>
                  <div
                    v-if='currentUser.permissions.can_write_discussions
                      && (msg === activeMessage)'
                    class='thread-action-menu'>
                    <ul>
                      <li v-if="!msg.is_hlt_response">
                        <a
                          @click="modifyMessageStatus(msg, 'Completed')"
                          class='message-mark-completed'
                        >Mark as completed</a>
                      </li>
                      <li v-if="!msg.is_hlt_response">
                        <a
                          @click="modifyMessageStatus(msg, 'Monitor')"
                          class='message-mark-monitor'
                        >Monitor message</a>
                      </li>
                      <li v-if="!msg.is_hlt_response">
                        <a
                          @click="modifyMessageStatus(msg, 'Needs Review')"
                          class='message-mark-review'
                        >Mark for review</a>
                      </li>
                      <li v-if="!msg.is_hlt_response">
                        <a
                          @click="modifyMessageStatus(msg, 'New')"
                          class='message-mark-new'
                        >Mark as new</a>
                      </li>
                      <li>
                        <a
                          v-if="currentUser.permissions.can_delete_discussions"
                          @click='deleteMessage(msg)'
                          class='message-mark-delete'
                        >
                          Delete Message
                        </a>
                      </li>
                      <li>
                        <a
                          v-if="currentUser.permissions.can_delete_discussions"
                          @click='shadowDeleteMessage(msg)'
                          class='message-mark-delete'
                        >{{ shadowDeleteText(msg) }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>{{ msg.message }}</p>
            </div>
          </div>
          <button
            v-if='(i !== activeThread) && currentUser.permissions.can_write_discussions'
            class='hlt-button secondary hlt-small-button show-reply-button'
            @click='toggleReplyBox(i, parentMsg)'
          >Reply to message</button>
          <div v-if='i === activeThread' class='reply-box'>
            <label for='reply-box'>Your Response:</label>
            <textarea v-model='parentMsg.replyMessage' name='reply-box' cols='30' rows='8' />
            <div class='reply-buttons'>
              <button
                v-if='currentUser.permissions.can_write_discussions'
                class='hlt-button secondary hlt-small-button reply-button'
                @click='reply(parentMsg)'
              >Reply</button>
              <button
                v-if='currentUser.permissions.can_delete_discussions'
                class='hlt-button default hlt-small-button cancel-button'
                @click='cancel(i)'
              >Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import railsRoutes from '../../../store/rails-routes.js'
import date from '../../../mixins/date'
import axios from 'axios'
import { mixin as clickaway } from 'vue-clickaway2'
import NewMessageSvg from '@/assets/images/icons/new-message-dot.svg'
import MonitorSvg from '@/assets/images/icons/clock-solid.svg'
import ReviewSvg from '@/assets/images/icons/comment-exclamation-solid.svg'
import CompletedSvg from '@/assets/images/icons/check-circle-solid.svg'

export default {
  components: {
    NewMessageSvg,
    MonitorSvg,
    ReviewSvg,
    CompletedSvg
  },
  mixins: [date, clickaway],
  props: {
    thread: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      activeThread: null,
      activeMessage: null,
      showMenu: null
    }
  },
  computed: {
    parentThread () {
      const parentArray = this.thread.filter(t => t.id === parseInt(t.path))
      return this._.orderBy(parentArray, ['created_at'], ['asc'])
    },
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    showActionMenu (message) {
      this.activeMessage = message
    },
    parentChildThread (parent) {
      const relationThread = this.thread.filter(
        p => parseInt(p.path.split('.')[0]) === parent.id
      )
      return this._.orderBy(relationThread, ['created_at'], ['asc'])
    },
    isAdmin (message) {
      return message.app_email === message.email
    },
    isShadowDeleted (msg) {
      return msg.shadow_deleted;
    },
    shadowDeleteText (msg) {
      if (msg.shadow_deleted) {
        return "Shadow Undelete"
      } else {
        return "Shadow Delete"
      }
    },
    cancel (index) {
      this.activeThread = null
    },
    reply (localAsset) {
      axios
        .post(
          railsRoutes.api_path({
            type: 'discussions',
            action: 'reply'
          }),
          {
            discussion: {
              message: localAsset.replyMessage,
              parent_discussion_id: localAsset.id
            }
          }
        )
        .then(response => {
          this.$notify({
            title: 'Successfully Replied to Message',
            type: 'success'
          })
          this.thread.push(response.data.records[0])
          localAsset.replyMessage = ''
          this.activeThread = null
        })
        .catch(error => {
          console.error(error)
          if (error.response?.data.error) {
            this.$notify({
              title: 'Missing Product Var',
              type: 'error',
              text: `Please have an Admin create a \'user_discussion_email\' product var for the app "${localAsset.app_name}"`,
              duration: -1
            })
          } else {
            this.$notify({
              title: 'Error Replying',
              type: 'error',
              text: 'There was a problem with your reply, please try again'
            })
          }
        })
    },
    modifyMessageStatus (localAsset, status) {
      localAsset.status = status
      axios
        .put(
          railsRoutes.api_path({
            type: 'discussions',
            id: localAsset.id
          }),
          {
            discussion: {
              status: localAsset.status
            }
          }
        )
        .then(response => {
          this.$notify({
            title: 'Successfully Updated Message',
            type: 'success'
          })
          this.activeMessage = null
        })
        .catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Updating Message', type: 'error' })
        })
    },
    shadowDeleteMessage (localAsset) {
      axios
        .post(
          railsRoutes.api_path({
            type: 'discussions',
            id: localAsset.id,
            action: 'shadow_delete'
          })
        )
        .then(response => {
          this.$notify({
            title: 'Successfully Updated.',
            type: 'success'
          })
          localAsset.shadow_deleted = !localAsset.shadow_deleted
          console.log(localAsset);
        })
        .catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Updating', type: 'error' })
        })
    },
    deleteMessage (localAsset) {
      axios
        .delete(
          railsRoutes.api_path({
            type: 'discussions',
            id: localAsset.id
          })
        )
        .then(response => {
          this.$notify({
            title: 'Successfully Deleted Message',
            type: 'success'
          })
          const routeDiscussion = this.thread.filter(
            r => r.id === parseInt(this.$route.params.id)
          )[0]
          const parentId = parseInt(routeDiscussion.path)
          if (
            this.$route.params.id === localAsset.id ||
            parentId === localAsset.id
          ) {
            this.$router.push({ name: 'discussions' })
          } else {
            this.getThread(this.$route.params.id)
          }
        })
        .catch(error => {
          console.error(error)
          this.$notify({ title: 'Error Deleting Message', type: 'error' })
        })
    },
    toggleReplyBox (index, thread) {
      this.activeThread = index
    },
    ...mapActions(['getThread'])
  }
}
</script>

<style lang="scss">
.shadow-deleted {
  color: red;
}
.reply-section-container {
  display: flex;
  flex-direction: column;
  button {
    justify-content: flex-end;
  }
  .inner-section {
    padding: 0 15px;
    height: auto;
    max-height: 90vh;
    padding-bottom: 140px;
    width: 100%;
    overflow-y: auto;
    .reply-container {
      padding-bottom: 100px;
      .thread-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $space-gray-10;
        .show-reply-button {
          align-self: flex-end;
        }
        .message-left {
          .app-name {
            color: $sky-blue;
            font-weight: 600;
          }
        }
      }
    }
    .message {
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 5px;
      .thread-action {
        width: 10px;
        height: 25px;
        background-image: radial-gradient(
          circle,
          $space-black 1px,
          transparent 2px
        );
        background-size: 100% 33.33%;
        &:hover {
          cursor: pointer;
        }
      }
      &.admin-message {
        background-color: lighten($sky-blue, 30%);
        margin-left: 25%;
        label {
          font-style: italic;
        }
      }
      &.user-message {
        background-color: $white-1;
        margin-right: 20%;
        label {
          font-style: italic;
        }
      }
      .message-row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        .message-left {
          flex: 2;
        }
        .message-right {
          display: flex;
          justify-content: flex-end;
          flex: 1;
          position: relative;
          .message-status-icon {
            margin: 0;
            padding: 0;
            height: 16px;
            width: 16px;
            position: absolute;
            top: 4px;
            right: 20px;
          }
          .thread-action-menu {
            position: absolute;
            top: 0;
            right: 10px;
            width: 200px;
            height: auto;
            margin: 0;
            background: $white;
            border-radius: 5px;
            border: 1px solid $space-gray-30;
            box-shadow: 1px 2px 6px $space-gray-20;
            ul {
              margin: 0;
              padding: 5px 10px;
              li {
                margin: 0;
                padding: 5px 0;
                list-style: none;
                a {
                  height: 2rem;
                  display: block;
                  padding: 5px 0;
                  margin-left: 5px;
                  color: $space-black-10;
                  font-size: 0.875rem;
                  font-weight: 600;
                  &:hover {
                    color: $sky-blue;
                  }
                }
              }
            }
          }
        }
      }
      .message-date {
        font-size: 0.75em;
      }
    }
    .reply-box {
      textarea {
        border-radius: 5px;
      }
    }
    .reply-buttons {
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 10px;
      }
      margin: 15px 0;
    }
  }
}
</style>
